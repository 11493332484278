<template>
  <div class="lambda">
    Lambda result: {{result}}
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Lambda',
  data() {
    return {
      result: undefined,
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`/${process.env.VUE_APP_LAMBDA_DIR}/lambda${process.env.VUE_APP_LAMBDA_SUFFIX}`);
      this.result = JSON.stringify(response.data);
    } catch (error) {
      this.result = JSON.stringify({ error: true });
    }
  },
}
</script>
